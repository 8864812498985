<template>
  <v-row justify="center">
    <v-dialog
      v-model="deleteSelectedDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('message.selection_deletion_definitive') }}</span>
        </v-card-title>
        <v-container>
          <v-card-text>
            <p v-if="successMessage">
              <v-alert
                text
                dense
                color="teal"
                border="left"
                type="success"
              >
                {{ successMessage }}
              </v-alert>
            </p>

            <div v-if="selectedTrashes.length">
              <span>{{ $t('message.permanent_selection_deletion') }}</span>
                <br>
                <br>
                <ul>
                  <li v-for="(trash, trashIndex) in selectedTrashes" :key="'t' + trashIndex">
                    <span v-if="trash.itemType === 'folder'" v-html="$t('trash_folder', { itemName: trash.itemName, childrenLength: trash.children.length })"></span>
                    <span v-if="trash.itemType === 'document'" v-html="$t('trash_document', { itemName: trash.itemName })"></span>
                    <span v-if="trash.itemType === 'secret'" v-html="$t('trash_secret', { itemName: trash.itemName })"></span>
                  </li>
                </ul>
            </div>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDeleteSelectedDialog()"
          >
            {{ $t('message.cancel') }}
          </v-btn>

          <button type="button" :disabled="loading" v-on:click="deleteSelected()" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
            <span class="v-btn__content">{{ $t('message.validate') }}</span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'

export default {
  name: 'DeleteSelectedDialog',
  data: () => ({
    successMessage: null,
    loading: false,
    errors: []
  }),
  props: {
    selectedTrashes: Array,
    deleteSelectedDialogState: Boolean
  },
  methods: {
    deleteSelected () {
      const trashIds = this.selectedTrashes.map(element => element.id)

      this.loading = true

      const data = {
        vault: this.$store.getters.getCurrentVault.id,
        ids: trashIds
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/trashes', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response && response.status === 204) {
            for (let i = 0; i < trashIds.length; i++) {
              this.$store.commit('deleteTrash', { id: trashIds[i] })
            }

            this.successMessage = this.$t('message.selection_has_been_permanently_deleted')
            this.errors = []
            this.closeDeleteSelectedDialog()
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false

          const response = e.response

          if (response && response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response && response.status === 500) {
            this.errors.push({
              title: this.$t('message.selection_deletion_error')
            })
          }
        })
    },
    closeDeleteSelectedDialog () {
      this.$emit('closeDeleteSelectedDialog', false)
    }
  }
}
</script>
