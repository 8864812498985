<template>
  <v-row justify="center">
    <v-dialog
      v-model="deleteDocumentDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('message.document_deletion_definitive') }}</span>
        </v-card-title>
        <v-container>
          <v-card-text>
            <p v-if="successMessage">
              <v-alert
                text
                dense
                color="teal"
                border="left"
                type="success"
              >
                {{ successMessage }}
              </v-alert>
            </p>

            <span v-if="trash" v-html="$t('message.permanent_document_deletion', { itemName: trash.itemName })"></span>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDeleteDocumentDialog()"
          >
            {{ $t('message.cancel') }}
          </v-btn>

          <button type="button" :disabled="loading" v-on:click="deleteDocument(trash)" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
            <span class="v-btn__content">{{ $t('message.validate') }}</span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '../../../../config'

export default {
  name: 'DeleteDocumentDialog',
  data: () => ({
    successMessage: null,
    loading: false,
    errors: []
  }),
  props: {
    trash: Object,
    deleteDocumentDialogState: Boolean
  },
  computed: {
    trashes () {
      return this.$store.getters.getTrashes
    }
  },
  methods: {
    deleteDocument (trash) {
      this.loading = true

      Vue.prototype.$http
        .delete(config.apiUrl + '/trashes/' + trash.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id
          }
        })
        .then(response => {
          if (response.status === 204) {
            this.$store.commit('deleteTrash', trash)
            this.successMessage = this.$t('message.document_has_been_permanently_deleted')
            this.errors = []
            this.closeDeleteDocumentDialog()
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false

          const response = e.response

          if (response && (response.status === 400 || response.status === 404)) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.document_deletion_error')
            })
          }
        })
    },
    closeDeleteDocumentDialog () {
      this.$emit('closeDeleteDocumentDialog', false)
    }
  }
}
</script>
