<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active">
              <span>{{ $t('message.trash') }}</span>
            </li>
          </ol>
        </nav>
        <div class="btn-mass-actions">
          <button class="btn btn-sm btn-primary" :title="$t('message.restore_selection')" @click="restoreSelected()" :disabled="checkboxCount <= 0"><i class="fas fa-trash-restore"></i><span>{{ $t('message.restore_selection') }}</span></button>
          <button class="btn btn-sm btn-danger" :title="$t('message.delete_selection_perma')" @click="openDeleteSelectedDialog()" :disabled="checkboxCount <= 0"><i class="fas fa-trash"></i><span>{{ $t('message.delete_selection_perma') }}</span></button>
          <button class="btn btn-sm btn-danger" :title="$t('message.empty_trash')" @click="openEmptyTrashDialog()" :disabled="trashes.length === 0 || checkboxCount > 0"><i class="fas fa-recycle"></i><span>{{ $t('message.empty_trash') }}</span></button>
        </div>
      </header>

      <v-alert
        text
        dense
        type="error"
        v-if="mkError !== null"
      >
        {{ mkError }}
      </v-alert>

      <table class="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-table-custom sm-2" >
        <thead>
        <tr>
          <th><v-checkbox dense v-model="checkbox" @change="updateCheckboxes()" class="document-checkbox"></v-checkbox></th>
          <th>{{ $t('message.name') }}</th>
          <th class="sm-hidden">{{ $t('message.trash_date') }}</th>
          <th class="sm-hidden">{{ $t('message.automatic_deletion_date') }}</th>
          <th>{{ $t('message.actions') }}</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(trash, trashIndex) in trashes" :key="'t' + trashIndex">
            <td><v-checkbox dense :value="trash.selected" v-model=trash.selected class="document-checkbox" @change="updateCheckboxCount(trash.selected)"></v-checkbox></td>
            <td :data-label="$t('message.name') + ' :'">
              <i :class='"el-type " + resolveIcon(trash)'></i><span class="ml-i2 font-weight-bold">{{ trash.itemName }}</span>
            </td>
            <td :data-label="$t('message.trash_date') + ' :'" class="sm-hidden">
              {{ trash.deletedAt ? formatDate(trash.deletedAt) : '-' }}
            </td>
            <td :data-label="$t('message.automatic_deletion_date') + ' :'" class="sm-hidden">
              {{ trash.finalDeletionAt ? formatDate(trash.finalDeletionAt) : '-'}}
            </td>
            <td class="actions" :data-label="$t('message.actions') + ' :'">
              <button v-if="isDownloadable(trash)" data-toggle="tooltip" data-placement="top" :title="$t('message.download')" @click="(trash.itemType === 'folder' ? downloadFolder(trash) : downloadDocument(trash))"><i class="fas fa-download"></i></button>
              <button data-toggle="tooltip" data-placement="top" :title="$t('message.restore')" @click="restore(trash)"><i class="fas fa-trash-restore"></i></button>
              <button data-toggle="tooltip" data-placement="top" :title="$t('message.delete')" @click="(trash.itemType === 'secret' ? openDeleteSecretDialog(trash) : trash.itemType === 'folder' ? openDeleteFolderDialog(trash) : openDeleteDocumentDialog(trash))"><i class="fas fa-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <DeleteDocumentDialog
      :key="Date.now()"
      :delete-document-dialog-state="deleteDocumentDialogState"
      :trash="documentToDelete"
      @closeDeleteDocumentDialog="closeDeleteDocumentDialog($event)"
    ></DeleteDocumentDialog>

    <DeleteFolderDialog
      :key="Date.now() + 1"
      :delete-folder-dialog-state="deleteFolderDialogState"
      :trash="folderToDelete"
      @closeDeleteFolderDialog="closeDeleteFolderDialog($event)"
    ></DeleteFolderDialog>

    <DeleteSelectedDialog
      :key="Date.now() + 2"
      :delete-selected-dialog-state="deleteSelectedDialogState"
      :selected-trashes="selectedTrashes"
      @closeDeleteSelectedDialog="closeDeleteSelectedDialog($event)"
    ></DeleteSelectedDialog>

    <EmptyTrashDialog
      :key="Date.now() + 3"
      :trashes-to-delete="trashesToDelete"
      :empty-trash-dialog-state="emptyTrashDialogState"
      @closeEmptyTrashDialog="closeEmptyTrashDialog($event)"
    ></EmptyTrashDialog>

    <DeleteSecretDialog
      :key="Date.now() + 4"
      :delete-secret-dialog-state="deleteSecretDialogState"
      :trash="secretToDelete"
      @closeDeleteSecretDialog="closeDeleteSecretDialog($event)"
    ></DeleteSecretDialog>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import Vue from 'vue'
import FileSaver from 'file-saver'
import config from '../../config'
import DeleteDocumentDialog from '../../components/Dialog/Trash/DocumentDialog/DeleteDocumentDialog'
import DeleteSecretDialog from '../../components/Dialog/Trash/SecretDialog/DeleteSecretDialog'
import DeleteFolderDialog from '../../components/Dialog/Trash/FolderDialog/DeleteFolderDialog'
import DeleteSelectedDialog from '../../components/Dialog/Trash/DeleteSelectedDialog'
import EmptyTrashDialog from '../../components/Dialog/Trash/EmptyTrashDialog'

export default {
  name: 'Trash',
  components: { DeleteFolderDialog, DeleteDocumentDialog, DeleteSecretDialog, DeleteSelectedDialog, EmptyTrashDialog },
  data: () => ({
    loading: false,
    checkbox: false,
    checkboxCount: null,
    mkError: null,
    selectedTrashes: [],
    documentToDelete: null,
    deleteDocumentDialogState: false,
    secretToDelete: null,
    deleteSecretDialogState: false,
    folderToDelete: null,
    deleteFolderDialogState: false,
    deleteSelectedDialogState: false,
    emptyTrashDialogState: false,
    trashesToDelete: [],
    nextPage: 1
  }),
  created () {
    this.$store.commit('setTrashes', [])
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - Corbeille'
    this.getTrashes()

    window.removeEventListener('scroll', this.handleScroll)

    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll)
    }, 1000)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)

    this.$store.commit('setTrashes', [])
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    trashes () {
      return this.$store.getters.getTrashes
    },
    isHandlingTrashScroll () {
      return this.$store.getters.isHandlingTrashScroll
    }
  },
  methods: {
    handleScroll () {
      const wrapperOffsetHeight = document.querySelector('.v-application--wrap').offsetHeight
      const bottomOfWindow = wrapperOffsetHeight - (document.documentElement.scrollTop + window.innerHeight) <= 200

      if (bottomOfWindow && !this.isHandlingTrashScroll) {
        this.getTrashes()
      }
    },
    getTrashes () {
      this.$store.commit('setHandlingTrashScroll', true)

      Vue.prototype.$http
        .get(config.apiUrl + '/trashes', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            page: this.nextPage
          }
        })
        .then(response => {
          if (response.status === 200) {
            const trashes = response.data

            if (trashes && trashes.length) {
              this.$store.commit('setTrashes', trashes)

              this.nextPage = this.nextPage + 1
            }

            this.loading = false
            this.$store.commit('setHandlingTrashScroll', false)
          }
        })
        .catch(e => {
          this.$store.commit('setTrashes', [])

          const response = e.response

          if (response.status === 404) {
            this.mkError = this.$t('message.service_maintenance')
          }

          this.loading = false
          this.$store.commit('setHandlingTrashScroll', false)
        })
    },
    downloadFolder (folder) {
      if (!folder) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/documents', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            format: 'zip',
            folder: folder.itemId,
            tree: folder.itemId,
            deleted: true
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            const blob = new Blob([response.data], { type: headers['content-type'] })
            FileSaver.saveAs(blob, folder.itemName)
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    downloadDocument (document) {
      if (!document) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/documents/' + document.itemId, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            let filename = document.itemName
            if (headers['content-name']) {
              filename = headers['content-name']
            }
            const blob = new Blob([response.data], { type: headers['content-type'] })
            FileSaver.saveAs(blob, filename)
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    restore (trash) {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/restore-trashes/' + trash.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (response.status === 200 && data) {
              this.$store.commit('deleteTrash', trash)
            }

            if (this.trashes.length === 0) {
              this.nextPage = this.nextPage - 1

              this.getTrashes()
            }

            this.loading = false
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.document_restoration_error')
            })
          }

          this.loading = false
        })
    },
    restoreSelected () {
      this.loading = true

      this.selectedTrashes = this.trashes
        .filter(element => element.selected === true)
        .map(element => element.id)

      const data = {
        vault: this.$store.getters.getCurrentVault.id,
        ids: this.selectedTrashes
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/restore-trashes', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (response.status === 200 && data) {
              for (let i = 0; i < this.selectedTrashes.length; i++) {
                this.$store.commit('deleteTrash', { id: this.selectedTrashes[i] })
              }
            }

            if (this.trashes.length === 0) {
              this.nextPage = this.nextPage - 1

              this.getTrashes()
            }

            this.checkbox = false
            this.updateCheckboxes()
            this.loading = false
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.elements_restoration_error')
            })
          }

          this.loading = false
        })
    },
    resolveIcon (trash) {
      if (trash.itemType === 'folder') {
        return 'fas fa-folder'
      }

      if (trash.itemType === 'document') {
        return 'mdi mdi-file-table-outline'
      }

      if (trash.itemType === 'secret') {
        return 'mdi mdi-key-variant'
      }
    },
    isDownloadable (trash) {
      if (trash.itemType === 'secret') {
        return false
      }

      const children = trash.children

      for (let i = 0; i < children.length; i++) {
        const currentChild = children[i]

        if (currentChild.itemType === 'secret') {
          return false
        }
      }

      return true
    },
    openDeleteDocumentDialog (document) {
      this.deleteDocumentDialogState = true
      this.documentToDelete = document
    },
    closeDeleteDocumentDialog () {
      this.deleteDocumentDialogState = false

      if (this.trashes.length === 0) {
        this.nextPage = this.nextPage - 1

        setTimeout(() => {
          this.getTrashes()
        }, 1000)
      }
    },
    openDeleteSecretDialog (secret) {
      this.deleteSecretDialogState = true
      this.secretToDelete = secret
    },
    closeDeleteSecretDialog () {
      this.deleteSecretDialogState = false

      if (this.trashes.length === 0) {
        this.nextPage = this.nextPage - 1

        setTimeout(() => {
          this.getTrashes()
        }, 1000)
      }
    },
    openDeleteFolderDialog (folder) {
      this.deleteFolderDialogState = true
      this.folderToDelete = folder
    },
    closeDeleteFolderDialog () {
      this.deleteFolderDialogState = false

      if (this.trashes.length === 0) {
        this.nextPage = this.nextPage - 1

        setTimeout(() => {
          this.getTrashes()
        }, 1000)
      }
    },
    openDeleteSelectedDialog () {
      this.selectedTrashes = this.trashes.filter(element => element.selected === true)

      if (this.selectedTrashes.length > 0) {
        this.deleteSelectedDialogState = true
      } else {
        this.checkboxCount = 0
      }
    },
    closeDeleteSelectedDialog () {
      this.deleteSelectedDialogState = false
      this.checkbox = false
      this.updateCheckboxes()

      if (this.trashes.length === 0) {
        this.nextPage = this.nextPage - 1

        setTimeout(() => {
          this.getTrashes()
        }, 1000)
      }
    },
    openEmptyTrashDialog () {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/trashes', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.trashesToDelete = response.data

            this.emptyTrashDialogState = true

            this.loading = false
          }
        })
        .catch(e => {
          this.loading = false
        })
    },
    closeEmptyTrashDialog () {
      this.emptyTrashDialogState = false
    },
    updateCheckboxes () {
      this.checkboxCount = 0

      for (let i = 0; i < this.trashes.length; i++) {
        const currentTrash = this.trashes[i]
        currentTrash.selected = this.checkbox
        this.updateCheckboxCount(currentTrash.selected)
      }
    },
    updateCheckboxCount (value) {
      value === true ? this.checkboxCount++ : (this.checkboxCount === 0 ? this.checkboxCount = 0 : this.checkboxCount--)
    },
    formatDate (currentDate) {
      const timestamp = Date.parse(currentDate)
      const date = new Date(timestamp)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let hour = date.getHours()
      let min = date.getMinutes()

      day = (day < 10 ? '0' : '') + day
      month = (month < 10 ? '0' : '') + month
      hour = (hour < 10 ? '0' : '') + hour
      min = (min < 10 ? '0' : '') + min

      return day + '/' + month + '/' + date.getFullYear() + ' ' + hour + ':' + min
    }
  }
}
</script>
